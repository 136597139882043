<template>
	<div class="section-17">
		<div :class="[ $mq.below(960) ? 'container-section-mobile' : 'container-section']">
			<div class="long-center-text" id="blackText17">
				<div class="paragraph-sect-17 paragraph-1 active" data-pId="paragraph-17-1">Comunitate exclusivă pentru antreprenorii TBF.</div><div class="paragraph-sect-17 paragraph-2" data-pId="paragraph-17-2"> Vei face parte dintr-un grup privat de Facebook, dedicat celor care folosesc TBF Digital și principiile de management TBF.</div><div class="paragraph-sect-17 paragraph-3" data-pId="paragraph-17-3"> Răzvan Căzănescu, fondatorul TBF, îți răspunde direct la întrebările tale.</div><div class="paragraph-sect-17 paragraph-4" data-pId="paragraph-17-4"> Faci parte dintr-o comunitate de antreprenori care respectă aceleași principii pentru management și creștere.</div>
				<div class="small-link">
					<router-link to="/tbf-digital/abonamente" target="_blank">Intră în comunitatea antreprenorilor care nu fac micromanagement <icon-arrow class="icon-arrow right"/></router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import IconArrow from '../../assets/landing/Arrow'
	import { gsap } from "gsap";
	import { ScrollTrigger } from "gsap/ScrollTrigger";

	export default {
		components:{
			IconArrow
		},
		mounted() {
			let paragraphs17 = gsap.utils.toArray(".paragraph-sect-17")

			gsap.to('.actions-fixed', {
				scrollTrigger:{
					trigger: '.section-17',
					start: "top 5%",
					end: "bottom 5%",
					onToggle: self2 => self2.isActive ? document.querySelector('.actions-fixed').classList.add('active-top-17') : document.querySelector('.actions-fixed').classList.remove('active-top-17'),
					scrub: true
				},
			})

			gsap.to('.actions-fixed', {
				scrollTrigger:{
					trigger: '.section-17',
					start: "top 95%",
					end: "bottom 95%",
					onToggle: self3 => self3.isActive ? document.querySelector('.actions-fixed').classList.add('active-bottom-17') : document.querySelector('.actions-fixed').classList.remove('active-bottom-17'),
					scrub: true
				}
			})

			paragraphs17.forEach((paragraphSect17, i) => {
				ScrollTrigger.create({
					trigger: paragraphSect17,
					start: "20 center",
					end: "bottom center",
					id: 'paragraph-17-' + i,
					onUpdate: self4 => this.checkHasActive(self4.isActive, paragraphSect17),
					onLeaveBack: () => this.checkFirstChild(paragraphSect17),
					onLeave: () => this.checkLastChild(paragraphSect17)
				})
			})
		},
		methods: {
			checkHasActive(active, paragraph){
				if(active){
					var paragraphs_active = document.querySelectorAll('.paragraph-sect-17.active')

					if(paragraphs_active.length > 0){
						paragraphs_active.forEach((paragraphActive) => {
							if(paragraphActive.getAttribute('data-pId') != paragraph.getAttribute('data-pId')){
								paragraphActive.classList.remove('active')
							}
						})
					}
					paragraph.classList.add('active')
				}else{
					paragraph.classList.remove('active')
				}
			},
			checkFirstChild(paragraph){
				if(paragraph.getAttribute('data-pId') == 'paragraph-17-1'){
					paragraph.classList.add('active')
				}
			},
			checkLastChild(paragraph){
				if(paragraph.getAttribute('data-pId') == 'paragraph-17-4'){
					paragraph.classList.add('active')
				}
			}
		}
	}
</script>